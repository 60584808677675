import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import {
  featuredWrapper,
  noFeaturedWrapper,
  color,
  wrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
  wrapper1200
} from '../../utils/style';
import Img from 'gatsby-image';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import Subcategories from '../../components/Content/Blog/Subcategories';


const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
  img {
    border-radius: 24px;
  }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
  h1 {
    font-size: 50px;
    margin: 0 0 -50px;    
  }
  
  @media (max-width: ${breakpoints.sm}) {
    h1 {
      font-size: 30px;
      margin-bottom: -40px;
    }
  }
  
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const ContentWrapper = styled('div')`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
`;

const PostContentWrapper = styled('div')`
  margin: 20px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .a-center {
    flex-basis: 100%;
    padding: 15px 0;
    min-height: 60px;
  }
  
  a {
    flex-basis: 32%;
    position: relative;
    margin: 10px 0;
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
    .image-wrap {
      position: relative;
    }
    
    .text-part-2 {
      padding: 15px 0;
    }
    
    .h5 {
      display: block;
      font-size: 22px;
      line-height: 25px;
      margin: 5px 0 15px;
      height: 75px;
      overflow: hidden;
    }
  
    .button {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -26px;
      margin-left: -77px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  
    .excerpt {
      display: block;
      font-size: 16px;
      line-height: 18px;
      line-height: 22px;
      max-height: 110px;
      overflow: hidden;
    }
  
    &:hover, &:active {
      text-decoration: none;
      color: ${color.white};
      border: none;
      .excerpt, .button {
        visibility: visible;
        opacity: 1;
      }
    }
    
  }
  
  @media (max-width: ${breakpoints.lg}) {
    a {
      flex-basis: 49%;
    }  
  }

  
  @media (max-width: ${breakpoints.sm}) {
    a {
      flex-basis: 100%;
      margin-bottom: 25px;
      .h5 {
        height: auto;
      }
    }  
  }
`;


const createGroups = (pages, pageLength) =>
  pages
    .map((edge, index) => {
      return index % pageLength === 0
        ? pages.slice(index, index + pageLength)
        : null;
    })
    .filter(item => item);

let nextPageIndex = 1;
const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node;
  const metaTitle = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title) ?
    currentCategory.yoast_meta.yoast_wpseo_title : currentCategory.title + "- " + process.env.OWNER;
  const metaDesc = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc) ?
    currentCategory.yoast_meta.yoast_wpseo_metadesc : '';
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;

  const pageLength = pageContext.pageLength;
  const groups = createGroups(data.allWordpressPost.edges, pageLength);
  const [items, setItems] = useState(groups[0].concat([]));
  const submenuLinkTitle = (currentCategory.wordpress_id === 19) ? 'All blog' : currentCategory.name;

  const loadMore = (e, index) => {
    nextPageIndex = nextPageIndex + 1;
    setItems(items.concat(groups[index]));
  };

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentCategory.path.replace('category/','')}
        key="seo"
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        {(currentCategory.acf && currentCategory.acf.category_image && currentCategory.acf.category_image.localFile) ?
          <FeaturedWrapper>
            <Img fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
                 alt={(currentCategory.acf.category_image.alt_text) ? currentCategory.acf.category_image.alt_text :
                   currentCategory.name}/>
            <h1 className="rich-design">
              <span dangerouslySetInnerHTML={{ __html: currentCategory.name }}/>
            </h1>
          </FeaturedWrapper>
          : <NoFeaturedWrapper>
            <h1 className="rich-design">
              <span dangerouslySetInnerHTML={{ __html: currentCategory.name }}/>
            </h1>
          </NoFeaturedWrapper>
        }
        {(currentCategory.wordpress_id === 19 || currentCategory.path.indexOf('blog')) > 0 &&
          <Subcategories title={submenuLinkTitle}/>
        }
        <PostContentWrapper>
          {items.map(({ node }) => (
            <Link key={node.slug} to={"/" + node.slug + "/"}>
              <div className="image-wrap">
                {(node.featured_media && node.featured_media.localFile && node.featured_media.localFile.childImageSharp && node.featured_media.localFile.childImageSharp.fluid) ?
                  <Img fluid={node.featured_media.localFile.childImageSharp.fluid}
                       alt={node.featured_media.alt_text ? node.featured_media.alt_text : node.title}/>
                  : <Img fluid={data.placeholder.childImageSharp.fluid} alt={node.title}/>
                }
                <span className="button gradient-button">
                  อ่านเพิ่มเติม
                </span>
              </div>
              <div className="text-part-2">
                <span className="h5">{node.title}</span>
                <div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }}/>
              </div>
            </Link>
          ))}
          {(groups.length > 1 && nextPageIndex <= groups.length - 1) &&
          <div className="a-center">
            <button className="button transparent-button" onClick={(e) => loadMore(e, nextPageIndex)}>
              <span>โหลดมากขึ้น...</span>
            </button>
          </div>

          }
        </PostContentWrapper>
        {currentCategory.description &&
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentCategory.description }}/>
        </ContentWrapper>
        }
      </WrapperDesktop>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(
      filter: { wordpress_id: {eq: $wordpress_id} }
    ) {
      edges {
        node {
          id
          name
          slug
          description
          path
          wordpress_id
          link
            acf {
              category_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            yoast_meta {
              yoast_wpseo_title
              yoast_wpseo_metadesc
            }
          }
        }
    }
    allWordpressPost(
      sort: { fields:date, order: DESC },
        filter: {
          categories: {
            elemMatch: {
              wordpress_id: {eq:$wordpress_id}
            }
          }
        }
      ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }  
`;
